import InfoSlider from "components/common/InfoSlider";
import { StaticQuery, graphql } from "gatsby";
import React from "react";

export default function RhombusBenefitsEducation() {
  const renderContent = data => {
    const benefits = [
      {
        title: "Keep Students and Faculty Safe with a User-Friendly Platform ",
        tabTitle: "User-Friendly",
        description:
          "Fortify school security with cloud-based cameras and real-time AI alerts for proactive threat detection anytime, anywhere. Cover blind spots, support School Resource Officers, and streamline investigations with easy-to-use tools.",
        image: data.benefit1,
      },
      {
        tabTitle: "Proactive Responses",
        title:
          "Accelerate Emergency Response and Coordinate with First Responders",
        description:
          "Act swiftly in emergencies and access a range of solutions, including person-of-interest detection, panic buttons, and alarm monitoring. Verify threats, reduce false alarms, and relay key details to emergency services efficiently.",
        image: data.benefit2,
      },
      {
        tabTitle: "Prevent Vaping",
        title: "Monitor Air Quality and Detect Student Vaping",
        description:
          "Maintain a healthy school environment and reduce student vaping with air quality and vape/smoke/THC smart sensors. Automatically detect e-cigarette and tobacco use in real time and obtain actionable evidence while preserving student privacy. ",
        image: data.benefit3,
      },
      {
        tabTitle: "Monitor Access Points",
        title: "Control Campus Perimeters and Building Access",
        description:
          "Centrally manage unlimited doors, buildings, and credentials from anywhere. Detect unauthorized visitors and quickly understand who’s on school property by pairing access events with synchronized video, badge data, and time stamps.",
        image: data.benefit4,
      },
    ];

    return (
      <InfoSlider
        data={benefits}
        title="The Benefits of Rhombus"
        color="var(--nuetral-100)"
      />
    );
  };
  const query = graphql`
    query {
      benefit1: file(
        relativePath: {
          eq: "components/webinar/updated/img/user-friendly-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit2: file(
        relativePath: {
          eq: "components/webinar/updated/img/proactive-responses-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit3: file(
        relativePath: {
          eq: "components/webinar/updated/img/prevent-vaping-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit4: file(
        relativePath: { eq: "components/webinar/updated/img/monitor-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
