import TrialBanner from "components/common/footer-banners/TrialBanner";
import IndustryLeadersCards from "components/common/sliders/IndustryLeadersCards";
import GlobalLayout from "components/page/GlobalLayout";
import OnDemandVideoHeader from "components/webinar/on-demand/OnDemandVideoHeader";
import { graphql } from "gatsby";
import React, { useEffect } from "react";
import debug from "debug";
import Helmet from "react-helmet";

import { Converter } from "showdown";
import RhombusBenefits from "components/webinars/templates/RhombusBenefits";
import RhombusBenefitsEducation from "components/webinars/templates/RhombusBenefitsEducation";

const log = debug("Thank-You");

const onDemandVideo = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
  } = data;

  useEffect(() => {
    if (!frontmatter.videoId && window) {
      window.location.href = "/404";
    }
  }, []);

  const converter = new Converter();
  const html = converter.makeHtml(frontmatter.aboutCopy);

  const renderBenefitsSection = topics => {
    if (topics.every(item => item === "Education")) {
      return <RhombusBenefitsEducation />;
    } else return <RhombusBenefits />;
  };

  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <script
          src={`https://fast.wistia.com/embed/medias/${frontmatter.videoId}.jsonp`}
          async
        />
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async />
        <script>
          {`
            email = localStorage.getItem("email");
            firstname = localStorage.getItem("firstname");
            lastname = localStorage.getItem("lastname");
            phone = localStorage.getItem("phone");
            title = localStorage.getItem("jobtitle");
            employees = localStorage.getItem("numberofemployees2");
            company = localStorage.getItem("company");
        
            window.referralJS = ((window.referralJS !== null && window.referralJS !== undefined) ? window.referralJS : {});
            window.referralJS.conversion = {
              debug: "false",
              parameters: {
                email: email,
                firstname: firstname,
                lastname: lastname,
                title: title,
                employees: employees,
                phone: phone,
                companyName: company
              }
            };
          `}
        </script>
      </Helmet>
      <OnDemandVideoHeader
        title={frontmatter.title}
        description={html}
        videoId={frontmatter.videoId}
      />
      {renderBenefitsSection(frontmatter.topics)}
      <IndustryLeadersCards />
      <TrialBanner />
    </GlobalLayout>
  );
};

export const PAGE_QUERY = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        videoId
        title
        aboutCopy
        topics
      }
    }
  }
`;

export default onDemandVideo;
